<template>
  <div>
    <sidebar :id="id" :menu="menu" :submenu="submenu" />
    <v-container fluid>
        <LoadData />
    </v-container>
  </div>
</template>

<script>
import Sidebar from "../../components/Sidebar.vue";
import LoadData from '../../components/MasterData/tipePenerimaan/loadData.vue'

export default {
  name: "Master_Data_Tipe_Penerimaan",

  components: { Sidebar, LoadData },

  metaInfo: {
    title: "Rhea Admin",
    titleTemplate: "%s - Master Data Tipe Penerimaan",
    htmlAttrs: {
      lang: "id",
      amp: true,
    },
  },

  data: () => ({
    id: "2",
    menu: "Master Data",
    submenu: " -> Tipe Penerimaan",
  }),
};
</script>